.calculator-page hr {
  border: none;
  height: 1px;
  background: #17313a;
  margin-top: 64px;
  margin-bottom: 64px;
}

.calculator {
  display: grid;
  grid-template-columns: 0.3fr 0.05fr 0.3fr 0.05fr 0.3fr;
  gap: 16px;
  width: 100%;
}

.calc-sign {
  font-size: 40px;
  margin: auto auto;
}

.reverse-breed {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.reverse-unit {
  margin: 0 10px;
}

.reverse-unit-img {
  width: 50px;
}

.reverse-pair {
  display: flex;
  align-items: center;
  padding: 10px 0;
  background-color: #102531;
  border: 1px solid #17313a;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
}

.calculator-shortest {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
  .calculator {
    gap: 4px;
  }

  .calc-sign {
    font-size: 32px;
    margin: auto auto;
  }
}

.iv-calculator {
  display: flex;
  gap: 15px;
}

@media only screen and (max-width: 600px) {
  .iv-calculator {
    flex-direction: column;
    gap: 0;
  }
}

.iv-calc-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 12px;
  align-items: center;
}

.soul-input {
  display: flex;
}

.soul-select {
  flex: 1;
}

.soul-icon {
  width: 25px;
  height: 25px;
}

.iv-input {
  width: auto;
}

.iv-stat {
  text-align: right;
}

.iv-skills {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
}
