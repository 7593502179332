.comp-info {
}

.comp-title-container {
  display: flex;
  justify-content: space-between;
}

.comp-title {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 10px;
}

.comp-user {
  margin-bottom: 10px;
}

.comp-description {
  margin-bottom: 10px;
}

.comp-image {
  margin-bottom: 20px;
}

.comp-units {
  margin-bottom: 20px;
}

.comp-units-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.comp-more-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.build-unit {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.build-unit-img {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.35);
  border: 2px solid #0d202b;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.35);
  width: 50px;
  margin-right: 10px;
}

.comp-event-add-title {
  margin-top: 10px;
  margin-bottom: 5px;
}

.build-actions {
  display: flex;
}

.unit-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  gap: 8px;
}

.unit-grid a {
  text-align: center;
}

.unit-grid-img {
  position: relative;
}

.unit-grid-count {
  position: absolute;
  right: 32px;
  bottom: 8px;
  background-color: #102431;
  padding: 4px 8px;
  border-radius: 8px;
  font-weight: bold;
}
