.event-page {
  margin-top: 20px;
}

.event-info {
  display: flex;
  margin-bottom: 10px;
}

.event-img {
  margin-right: 20px;
  width: 200px;
}

.event-page-event-img {
  border-radius: 10px;
}
