.comp-step {
  margin-bottom: 15px;
}

.step-title {
  margin-top: 0;
  margin-bottom: 10px;
}

.step-description {
  margin-bottom: 10px;
}
