.comps-page-content {
  display: grid;
  grid-template-columns: 3fr 1fr;
  /* grid-template-columns: repeat(auto-fill, minmax(50%, 1fr)); */
}

@media only screen and (max-width: 600px) {
  .comps-page-content {
    display: block;
  }
}

.comps-page-form {
  margin-right: 20px;
}

.comps-tags {
  position: absolute;
  top: 10px;
  right: 10px;
}

.comps-video-tag {
  background-color: #459aea;
  padding: 4px 6px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 14px;
}

.comps-cost-tag {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 4px 6px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 14px;
}

.comp-container {
  display: flex;
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .comp-container {
    flex-wrap: wrap;
  }
}

.comp-create-unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.comp-create-unit-left {
  display: flex;
  align-items: center;
}

.comp-create-unit-right {
  vertical-align: middle;
}

.comp-create-amount {
  max-width: 100px;
  margin-right: 10px;
}

.comp-create-property {
  max-width: 100px;
  margin-left: 10px;
}

.comp-like-container {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.comp-like {
  background: none;
  color: white;
  border: none;
  font-size: 20px;
}

.comp-step-trash {
  vertical-align: middle;
  color: #d32029;
}

.comp-step-trash:hover {
  color: #e84749;
}

.create-comp-header {
  font-weight: bold;
  font-size: 18px;
}

.comp-create-btn {
  background-color: #f67650;
  border-color: #f67650;
}

.comp-create-btn:hover {
  background-color: rgb(255, 147, 115);
  border-color: rgb(255, 147, 115);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tiktok-container {
  overflow: hidden;
  max-width: 325px;
  margin: auto;
  max-height: 593px;
  margin-top: -18px;
}

.comp-create-tag-img {
  width: 20px;
}

.tag-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 16px;
  margin-bottom: 15px;
}

.tag-stat-category {
  display: flex;
  flex-direction: column;
  min-width: 240px;
}

@media only screen and (max-width: 600px) {
  .tag-stat-category {
    width: 100%;
  }
}

.stat-container {
  padding-left: 8px;
  padding-right: 8px;
}

.stat-container.Elements {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 16px;
}

.comp-stats-tag-img {
  width: 30px;
  vertical-align: middle;
}

.tag-stat-title {
  margin: 0;
  font-weight: bold;
  background-color: #102531;
  border: 1px solid #17313a;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 16px;
}

.tag-stat-title-key {
  color: gray;
  font-size: 13px;
}

.stat-container {
  margin-top: 16px;
}

.stat-name {
  font-weight: bold;
}

.stat.Stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}

.stat.PartnerSkill {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.stat-data {
  margin-left: 4px;
}

.stat-table {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  row-gap: 4px;
}

.stat-table-cell {
  min-width: 48%;
  background-color: rgb(9, 49, 57);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 4px;
}

.stat-table-cell.inactive {
  background-color: rgb(2, 14, 16);
}

.stat-table-cell.active {
  color: white;
}

.stat-table-cell.inactive {
  color: gray;
}

.comp-stats-tag-img.inactive {
  filter: brightness(0.5) grayscale(100%);
}

.comp-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: calc(30%);
}

@media only screen and (max-width: 600px) {
  .comp-left {
    width: 100%;
    margin-bottom: 15px;
  }
}

.build-page-unit {
  width: 50px;
  margin-right: 15px;
}

.build-page-unit-name {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: pre;
}

@media only screen and (max-width: 600px) {
  .stat-table-cell {
    min-width: 100%;
  }
}

/* CREATE GRID */

.comp-create-grid {
  display: grid;
  gap: 10px;
}

.comp-create-grid-unit {
  text-align: center;
  background-color: #102531;
  padding: 15px;
  border: 1px solid #17313a;
  border-radius: 10px;
  position: relative;
}

.comp-create-grid-top {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  height: 100%;
}

.comp-create-grid-label {
  /* margin-bottom: 5px; */
}

.comp-create-grid-bot {
  position: absolute;
  top: 5px;
  right: 0px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.create-grid-unit-img {
  /* max-width: 75px; */
  /* max-height: 75px; */
  margin-bottom: 8px;
}
