.units-page {
  margin-top: 20px;
}

.units-page-unit {
  font-size: 18px;
  margin-bottom: 15px;
}

.units-page-unit-img {
  border-radius: 10px;
  margin-bottom: 5px;
}

/* UNIT LIST */
.units-page-list {
  display: grid;
  gap: 12px;
}

.units-list-unit {
  position: relative;
  margin: 5px 5px;
  text-align: center;
  background-color: #102531;
  border: 1px solid #17313a;
  border-radius: 10px;
  transition: all 0.1s linear;
  /* padding: 20px 20px 0 20px; */
  padding: 24px 40px;
}

.units-list-unit:hover {
  background-color: #122936;
}

.units-list-unit img {
  /* max-width: 100px; */
}

.unit-list-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.unit-list-category {
  display: block;
  margin-bottom: 5px;
}

.unit-list-tags {
  display: flex;
  flex-wrap: wrap;
}

.unit-list-tag {
  border-radius: 5px;
  padding: 2px 5px;
  background-color: #2c2e30;
  margin-bottom: 5px;
  margin-right: 5px;
}

.unit-list-tag-num {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  gap: 1px;
  padding-right: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #17313a;
  margin-bottom: 1px;
}

.unit-list-tag-img {
  display: block;
  width: 24px;
}

.units-page-banner-right .unit-list-tag-img {
  width: 18px;
}

.units-page-banner-left {
  position: absolute;
  top: 5px;
  left: 5px;
}

.units-page-banner-right {
  position: absolute;
  top: 5px;
  right: 2px;
}
