.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #102531;
  border-top: 1px solid #17313a;
  color: white;
  margin-top: 20px;
}

.logout-btn {
  background-color: transparent;
  border: none;
  color: unset;
  cursor: pointer;
  font-size: 15px;
  text-decoration: none;
}

.footer-item {
  margin-bottom: 5px;
}

.footer-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-item a {
  text-decoration: none;
  color: unset;
}

.footer-item a:hover {
  text-decoration: underline;
}

.footer-socials a {
  margin-right: 10px;
  font-size: 20px;
}

.footer-logo {
  max-width: 150px;
}

@media only screen and (min-width: 600px) {
  .footer {
    flex-direction: row;
  }
}
