.unit-page {
  margin-top: 20px;
}

.unit-info {
  display: flex;
  margin-bottom: 10px;
}

.unit-img {
  margin-right: 20px;
  width: 200px;
}

.unit-page-img {
  border-radius: 10px;
}

.unit-title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}

.zonaite-cost {
  display: flex;
  align-items: center;
}

.unit-tag-banner {
  width: 30px;
  height: 30px;
}

hr {
  border: none;
  height: 1px;
  background: #17313a;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 600px) {
  .unit-info {
    flex-direction: column;
  }
}
