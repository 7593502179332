.sources-page {
  margin-top: 20px;
}

.sources-source-container {
  margin-bottom: 20px;
}

.sources-source {
  /* display: flex; */
  background-color: #202224;
  border-radius: 20px;
  padding: 15px;
  height: 100%;
}

.sources-source-left {
  margin-right: 10px;
}

.sources-source-name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
}

.sources-source-img {
  height: auto !important;
  max-width: 150px;
}
