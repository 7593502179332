.events-page {
  margin-top: 20px;
}

.events-top-bar {
  margin-bottom: 10px;
}

.events-page-event {
  margin-bottom: 15px;
}

.events-page-event-img {
  border-radius: 10px;
  margin-bottom: 5px;
}

.events-page-spoiler {
  border-radius: 10px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #202224;
}

.events-spoilers-filter {
  display: flex;
  align-content: center;
}

.events-spoilers-filter input {
  width: auto;
}
