.login {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.login input,
.login .btn {
  width: 100%;
}

.login .container {
  min-width: 300px;
  max-width: 350px;
}

.login-input {
  margin-bottom: 16px;
}

.login-form {
  background: white;
  border-radius: 5px;
}

.login-btn-bar {
  margin-bottom: 20px;
}

.signup-sublabel {
  color: gray;
  font-size: 13px;
  margin-top: 5px;
}

.discord-login {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #7289da;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-decoration: none;
}

.discord-login:hover {
  background-color: #4e5d94;
}

.or {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.or-line {
  flex: 1;
  border-top: 1px solid lightgray;
  margin: 0 10px;
}
