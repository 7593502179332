@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

body {
  font-family: 'Roboto';
}

div {
  box-sizing: border-box;
}

img {
  width: 100%;
}

.content {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.btn,
.btn-alt,
.btn-alt2 {
  font-family: inherit;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  padding: 7px 15px;
  text-decoration: none;
  transition: 0.2s;
}

.btn,
.btn-alt,
.btn-alt2:disabled {
  background-color: gray;
}

.btn {
  background-color: #459aea;
  border: 2px solid #459aea;
  color: white;
}

.btn:hover {
  background-color: #5ca7ed;
  border: 2px solid #5ca7ed;
}

.btn-alt {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.btn-alt:hover {
  color: #459aea;
  background-color: white;
}

.btn-alt2 {
  background-color: transparent;
  border: 2px solid #459aea;
  color: #459aea;
}

.btn-alt2:hover {
  color: white;
  background-color: #459aea;
}

.btn-alt3 {
  background-color: transparent;
  border: 1px solid #17313a;
  color: white;
}

.btn-alt3:hover {
  color: white;
  background-color: transparent;
  border: 1px solid #f27831;
}

.btn-icon {
  font-size: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-icon:hover {
  color: #459aea;
}

.btn-link {
  background: none;
  color: inherit;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.btn-bar {
  display: flex;
  justify-content: space-between;
}

input,
textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid hsl(195, 43%, 16%);
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  font-family: inherit;
}

textarea {
  background-color: #102531 !important;
}

select {
  appearance: none;
  border-radius: 5px;
  border: 1px solid #17313a;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
}

.input-row {
  margin-bottom: 10px;
}

.input-label {
  margin-bottom: 5px;
}

.input-checkbox input {
  width: auto;
}

.error {
  color: red;
}

.capitalize {
  text-transform: capitalize;
}

.tab-link {
  display: inline-block;
  color: unset;
  margin-right: 10px;
  text-decoration: none;
  padding-bottom: 5px;
  border-bottom: 3px solid transparent;
  white-space: nowrap;
}

.tab-link:hover {
  border-bottom: 3px solid gray;
}

.tab-link-active {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  color: unset;
  text-decoration: none;
  border-bottom: 3px solid #459aea;
  padding-bottom: 5px;
}

.container-fluid {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

/* table tr:nth-child(odd) td {
  background-color: lightgray;
} */

/* Override */
@media only screen and (max-width: 600px) {
  .container-fluid {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .row {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .col-xs,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.option-item {
  display: flex;
  align-items: center;
}

.option-img {
  width: 64px;
  margin-right: 10px;
}
