.leaflet-control-attribution {
  display: none;
}

.leaflet-container {
  background: #0f2339 !important;
}

.map-page {
  padding-top: 30px;
}

.map-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.map-icons button {
  width: 50px;
  padding: 0;
}
