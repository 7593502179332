.tag-container {
}

.tag {
  /* padding: 5px 10px; */
  margin: 10px 0;
  /* background-color: #f27831; */
  color: white;
  border-radius: 6px;
  /* font-weight: bold; */
  margin-right: 10px;
}

.tag-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag-label {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
}

.tag-filters {
  margin-bottom: 10px;
  /* max-width: 100%;
  overflow: auto; */
}

.tag-filters-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tag-filters hr {
  border: none;
  height: 1px;
  background: #17313a;
  margin-top: 24px;
  margin-bottom: 24px;
}

.tag-filter {
  background-color: transparent;
  font-size: 15px;
  padding: 2px 5px;
  padding-top: 6px;
  color: white;
  border-radius: 6px;
  margin-right: 10px;
  border: 1px solid #f27831;
  margin-bottom: 10px;
  transition: all 0.1s ease-in-out;
}

.tag-filter-active {
  background-color: #f27831;
  font-weight: bold;
}

.tag-filter-category {
  margin-bottom: 15px;
}

.tag-filter-category-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.tag-filter-img {
  width: 30px;
}

.tag-category {
  margin-bottom: 20px;
}

.tag-category-title {
  font-weight: bold;
  max-width: 300px;
  border-radius: 5px;
  padding: 10px;
  background-color: #102531;
  border: 1px solid #17313a;
  margin-bottom: 10px;
}

.tags-tag-img {
  width: 30px;
  vertical-align: middle;
}

.tag-card {
  border-radius: 5px;
  padding: 10px;
  background-color: #102531;
  border: 1px solid #17313a;
  margin-bottom: 10px;
}

.tag-description {
  margin-top: 5px;
}

.tag-description-line {
  margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
  .tag-filters {
    padding: 0px 12px;
  }
}
