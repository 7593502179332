.home-page {
  margin-top: 10px;
}

.build-page-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
}

.build-page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.sort-bar {
}

.load-bar {
  display: flex;
  justify-content: center;
}
